/* eslint-disable jsx-a11y/anchor-is-valid */
// import React, { useEffect, useState } from "react";
// import Dropdown from "../../../../shared/components/dropdown/Dropdown";
// import {
//   IPerspectiveTab,
//   ITAB_ID,
//   MAP_TAB,
//   ALL_TAB,
//   FINANCIAL_TAB,
//   CUSTOMER_TAB,
//   PROCESS_TAB,
//   GROWTH_TAB,
// } from "../../../../shared/interfaces/IPerspectiveTabs";
// import "./Tabs.scss";

import {
  ALL_TAB,
  CUSTOMER_TAB,
  FINANCIAL_TAB,
  GROWTH_TAB,
  ITAB_ID,
  MAP_TAB,
  PROCESS_TAB,
} from "../../../../shared/interfaces/IPerspectiveTabs";

// interface ITabItem {
//   tab: IPerspectiveTab;
//   isActive: (tab: string) => boolean;
//   onClickTab: (tab: ITAB_ID) => void;
// }
// const OptionItem = (props: ITabItem) => {
//   const { tab, isActive, onClickTab } = props;

//   const activeCss = isActive(tab.id)
//     ? "option-item option-item__active"
//     : "option-item";

//   return (
//     <div className={activeCss} onClick={() => onClickTab(tab.id)}>
//       <div className="active-icon">
//         <span data-uk-icon="icon: check; ratio: .8"></span>
//       </div>
//       <p className="option-item__name">{tab.name}</p>
//       <p className="option-item__message">{tab.description}</p>
//     </div>
//   );
// };

// interface IProps {
//   tab: ITAB_ID;
//   setTab: React.Dispatch<React.SetStateAction<ITAB_ID>>;
// }
// const Tabs = (props: IProps) => {
//   const { tab, setTab } = props;
//   const [tabName, setTabName] = useState<string>("");

//   const isActive = (_tab: string) => {
//     return _tab === tab ? true : false;
//   };

//   const onClickTab = (tab: ITAB_ID) => {
//     setTab(tab);
//   };

//   useEffect(() => {
//     if (tab === MAP_TAB.id) setTabName(MAP_TAB.name);
//     else if (tab === ALL_TAB.id) setTabName(ALL_TAB.name);
//     else if (tab === FINANCIAL_TAB.id) setTabName(FINANCIAL_TAB.name);
//     else if (tab === CUSTOMER_TAB.id) setTabName(CUSTOMER_TAB.name);
//     else if (tab === PROCESS_TAB.id) setTabName(PROCESS_TAB.name);
//     else if (tab === GROWTH_TAB.id) setTabName(GROWTH_TAB.name);
//     else setTab(tab);

//     return () => {};
//   }, [tab]);

//   return (
//     <div className="perspective-filters">
//       <div>
//         <button className="btn btn-primary">
//           {tabName} <span data-uk-icon="icon: triangle-down"></span>
//         </button>

//         <Dropdown pos="bottom-left">
//           <li className="kit-dropdown__content">
//             <OptionItem
//               tab={MAP_TAB}
//               isActive={isActive}
//               onClickTab={onClickTab}
//             />
//             <OptionItem
//               tab={ALL_TAB}
//               isActive={isActive}
//               onClickTab={onClickTab}
//             />
//             <OptionItem
//               tab={FINANCIAL_TAB}
//               isActive={isActive}
//               onClickTab={onClickTab}
//             />
//             <OptionItem
//               tab={CUSTOMER_TAB}
//               isActive={isActive}
//               onClickTab={onClickTab}
//             />
//             <OptionItem
//               tab={PROCESS_TAB}
//               isActive={isActive}
//               onClickTab={onClickTab}
//             />
//             <OptionItem
//               tab={GROWTH_TAB}
//               isActive={isActive}
//               onClickTab={onClickTab}
//             />
//           </li>
//         </Dropdown>
//       </div>
//     </div>
//   );
// };

// export default Tabs;

interface ITabItem {
  label: ITAB_ID;
  name: string;
  tooltip?: string;
  activeTab: (tab: string) => "" | "uk-active";
  onClick: (tab: ITAB_ID) => void;
}
const TabItem = (props: ITabItem) => {
  const { label, name, tooltip, activeTab, onClick: onClickTab } = props;

  return (
    <li
      className={activeTab(label)}
      onClick={() => onClickTab(label)}
      title={tooltip || label}
    >
      <a href="#">{name}</a>
    </li>
  );
};

interface IProps {
  noMap?: boolean;
  tab: ITAB_ID;
  setTab: React.Dispatch<React.SetStateAction<ITAB_ID>>;
}
const Tabs = (props: IProps) => {
  const { tab, setTab, noMap = false } = props;

  const activeTab = (_tab: string) => {
    return tab === _tab ? "uk-active" : "";
  };

  const onClick = (label: ITAB_ID) => {
    setTab(label);
  };

  return (
    <div className="perspective-filters">
      <ul className="kit-tabs uk-margin-remove" data-uk-tab>
        {!noMap && (
          <TabItem
            label={MAP_TAB.id}
            name="Map"
            activeTab={activeTab}
            onClick={onClick}
            tooltip="Display the objectives in a strategic map"
          />
        )}
        <TabItem
          label={ALL_TAB.id}
          name="All"
          activeTab={activeTab}
          onClick={onClick}
          tooltip="Show all the objectives"
        />
        <TabItem
          label={FINANCIAL_TAB.id}
          name="Financial"
          activeTab={activeTab}
          onClick={onClick}
          tooltip="Only show the financial perspective objectives"
        />
        <TabItem
          label={CUSTOMER_TAB.id}
          name="Customer"
          activeTab={activeTab}
          onClick={onClick}
          tooltip="Only show the customer perspective objectives"
        />
        <TabItem
          label={PROCESS_TAB.id}
          name="Process"
          activeTab={activeTab}
          onClick={onClick}
          tooltip="Only show the internal process perpective objectives"
        />
        <TabItem
          label={GROWTH_TAB.id}
          name="Learning"
          activeTab={activeTab}
          onClick={onClick}
          tooltip="Only show the learning and growth perspective objectives"
        />
      </ul>
    </div>
  );
};

export default Tabs;
